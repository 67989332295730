<template>
  <div>
    <van-tabbar>
      <van-tabbar-item icon="home-o" to="/workList">首页</van-tabbar-item>
      <van-tabbar-item icon="setting-o" @click="showPopup">客服</van-tabbar-item>
    </van-tabbar>

    <van-popup v-model="show">
        <img src="~assets/serve.jpg" alt="" style="weight:150px;height:150px;">
    </van-popup>
  </div>
</template>

<script>
import { Tabbar, TabbarItem, Icon, Popup } from "vant";
export default {
  name: "TabBar",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
      showPopup() {
      this.show = true;
    },
  },
};
</script>